<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <v-select
              v-model="id_com_brand"
              label="title"
              :options="brands"
              placeholder="Marka Seçiniz"
              :reduce="brand => brand.id"
              :close-on-select="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <v-select
              v-model="id_com_meet"
              label="title"
              :options="meets"
              placeholder="Temas Tipi Seçiniz"
              :reduce="meet => meet.id"
              :close-on-select="false"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <b-form-datepicker
              v-model="sdate"
              v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <b-form-datepicker
              v-model="edate"
              v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            :disabled="sdate == null || edate == null || id_com_meet.length === 0"
            @click="getReport"
          >
            <FeatherIcon icon="FilterIcon" />
            Görüntüle
          </b-button>
          <b-button
            v-if="digitalResponse.length > 0"
            variant="success"
            class="ml-1"
            :disabled="sdate == null || edate == null || id_com_meet.length === 0"
            :href="downloadExcel"
            target="_blank"
          >
            <FeatherIcon icon="FileIcon" />
            Excel Aktar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="digitalResponse.length > 0"
      no-body
    >
      <b-table
        :fields="fields"
        :items="digitalResponse"
        striped
        hover
        responsive
      >
        <template #cell(username)="data">
          <b-row>
            <b-col>
              <div>{{ data.item.username }}</div>
              <small class="text-warning">{{ data.item.brand }}</small>
            </b-col>
            <b-col
              cols="auto"
              class="text-right p-0 m-0"
            >
              <div class="text-warning font-small-2">
                <b-button
                  v-b-tooltip.hover.top="'Görüşmeyi Görüntüle'"
                  :to="'/interviews/view/' + data.item.interview_number"
                  variant="flat-primary"
                  size="sm"
                  target="_blank"
                  class="btn-icon"
                  pill
                >
                  <FeatherIcon icon="EyeIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </template>
        <template #cell(request)="data">
          <div>{{ moment(data.item.request).format('llll') }}</div>
        </template>
        <template #cell(response)="data">
          <div v-if="data.item.response">
            {{ moment(data.item.response).format('llll') }}
          </div>
          <div v-else>
            <div class="font-weight-bolder text-danger font-medium-2">
              {{ Math.abs(moment(data.item.request).diff(moment().format(),'minutes')) }} dk
            </div>
            <div class="text-danger font-small-2">
              Beklemede
            </div>
          </div>
        </template>
        <template #cell(dateDiff)="data">
          <div v-if="data.item.dateDiff">
            <span v-if="data.item.dateDiff.days">{{ data.item.dateDiff.days }} Gün </span>
            <span v-if="data.item.dateDiff.hours">{{ data.item.dateDiff.hours }} Saat </span>
            <span v-if="data.item.dateDiff.minutes">{{ data.item.dateDiff.minutes }} Dakika </span>
          </div>
          <div v-else>
            -
          </div>
          <div
            v-if="data.item.ads"
            class="text-danger font-small-2"
          >
            (Dijital Reklam)
          </div>
          <div
            v-else
            class="text-muted font-small-2"
          >
            (Organik)
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-row>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col cols="auto">
            Toplam {{ dataCount }} adet sonuç bulundu.
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BRow, BCol, BFormDatepicker, BButton, BPagination, BCardFooter, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormDatepicker,
    BButton,
    BPagination,
    BCardFooter,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      id_com_brand: [],
      id_com_meet: [],
      sdate: null,
      edate: null,
      limit: 50,
      start: 0,
      currentPage: 1,
      perPage: 50,
      fields: [
        {
          key: 'username',
          label: 'Danışman',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'request',
          label: 'Talep Tarihi',
          sortable: false,
          thClass: 'text-center width-200',
          tdClass: 'text-center width-200 font-small-2',
        },
        {
          key: 'response',
          label: 'İlk Yanıt',
          sortable: false,
          thClass: 'text-center width-200',
          tdClass: 'text-center width-200 font-small-2',
        },
        {
          key: 'dateDiff',
          label: 'Detay',
          sortable: true,
          thClass: 'text-right width-200',
          tdClass: 'text-right width-200',
        },
      ],
    }
  },
  computed: {
    digitalResponse() {
      return this.$store.getters['webformDigitalResponse/getReport']
    },
    dataCount() {
      return this.$store.getters['webformDigitalResponse/getReportDataCount']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
    downloadExcel() {
      const urlParams = []
      if (this.sdate) {
        urlParams.push(`sdate=${this.sdate}`)
      }
      if (this.edate) {
        urlParams.push(`edate=${this.edate}`)
      }
      if (this.id_com_brand) {
        this.id_com_brand.forEach(e => {
          urlParams.push(`id_com_brand[]=${e}`)
        })
      }
      if (this.id_com_meet) {
        this.id_com_meet.forEach(e => {
          urlParams.push(`id_com_meet[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/webforms/digital_response/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  watch: {
    currentPage(val) {
      this.start = (val - 1) * 50
      this.getReport()
    },
  },
  created() {
    this.getBrands()
    this.getMeets()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
      })
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: [
          'com_meet.id AS id',
          'com_meet.title AS title',
        ],
        where: {
          'com_meet.id_com_meet_group': 1,
        },
      })
    },
    getReport() {
      this.$store.dispatch('webformDigitalResponse/report', {
        id_com_brand: this.id_com_brand,
        id_com_meet: this.id_com_meet,
        sdate: this.sdate,
        edate: this.edate,
        limit: this.limit,
        start: this.start,
      })
    },
  },
}
</script>
